export let strategyTplData = [{
        "addAdRuleRequests": [{
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "账户余额过低 - 金额",
                "conditions": [{
                    "field": "balance",
                    "operator": "LESS_THAN",
                    "value": 100,
                    "type": "INTEGER"
                }, {
                    "field": "schedule_interval_minutes",
                    "operator": "EQUAL",
                    "value": "60",
                    "type": "INTEGER"
                }, {
                    "field": "notification_times",
                    "operator": "EQUAL",
                    "value": "3",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            },
            "type": 2
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "账户余额过低 - 剩余天数",
                "conditions": [{
                    "field": "remain_days",
                    "operator": "LESS_THAN",
                    "value": 3,
                    "type": "INTEGER"
                }, {
                    "field": "schedule_start_time",
                    "operator": "EQUAL",
                    "value": "10:00:00",
                    "type": "STRING"
                }, {
                    "field": "schedule_interval_minutes",
                    "operator": "EQUAL",
                    "value": "1440",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            },
            "type": 2
        }],
        "strategyRequest": {
            "accountNo": "",
            "campaigns": [],
            "name": "广告账户余额过少提醒策略",
            "status": 0,
            "type": 2
        }
    },
    {
        "addAdRuleRequests": [{
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "花费太少通知",
                "conditions": [{
                    "field": "daily_budget",
                    "operator": "LESS_THAN",
                    "value": 1,
                    "type": "INTEGER"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "660",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "TODAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "曝光太少通知",
                "conditions": [{
                    "field": "impressions",
                    "operator": "LESS_THAN",
                    "value": 200,
                    "type": "INTEGER"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "660",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "TODAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }],
        "strategyRequest": {
            "accountNo": "",
            "campaigns": [],
            "name": "广告组投放不出通知策略",
            "status": 0,
            "type": 2
        }
    },
    {
        "addAdRuleRequests": [{
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "CPA过高监控",
                "conditions": [{
                    "field": "cpa",
                    "operator": "GREATER_THAN",
                    "value": 9,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "GREATER_THAN",
                    "value": "3",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "YESTERDAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "无转化通知（累计）",
                "conditions": [{
                    "field": "spent",
                    "operator": "GREATER_THAN",
                    "value": 30,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "EQUAL",
                    "value": "3",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "LIFETIME",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "无转化通知（天）",
                "conditions": [{
                    "field": "spent",
                    "operator": "GREATER_THAN",
                    "value": 30,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "EQUAL",
                    "value": "3",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "YESTERDAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }],
        "strategyRequest": {
            "accountNo": "",
            "campaigns": [{
                "id": "23846250470020752",
                "name": "925929895#博2.28增高气垫摇摇鞋女-Copy"
            }, {
                "id": "23846065417880752",
                "name": "新广告系列"
            }],
            "name": "广告组CPA过高通知策略",
            "status": 0,
            "type": 2
        }
    },
    {
        "addAdRuleRequests": [{
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "CPA过高暂停广告组",
                "conditions": [{
                    "field": "cpa",
                    "operator": "GREATER_THAN",
                    "value": 3,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "GREATER_THAN",
                    "value": "3",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "YESTERDAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "PAUSE",
                    "struct": {}
                }, {
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "无转化暂停广告组（累计）",
                "conditions": [{
                    "field": "spent",
                    "operator": "GREATER_THAN",
                    "value": 30,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "EQUAL",
                    "value": "0",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "LIFETIME",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "无转化暂停广告组（天）",
                "conditions": [{
                    "field": "spent",
                    "operator": "GREATER_THAN",
                    "value": 30,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "EQUAL",
                    "value": "0",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "YESTERDAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "CPA符合预期重启广告组",
                "conditions": [{
                    "field": "cpa",
                    "operator": "LESS_THAN",
                    "value": 4,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "GREATER_THAN",
                    "value": "3",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "YESTERDAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "UNPAUSE",
                    "struct": {}
                }, {
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }],
        "strategyRequest": {
            "accountNo": "",
            "campaigns": [],
            "name": "广告组CPA简单启停通知策略",
            "status": 0,
            "type": 2
        }
    },
    {
        "addAdRuleRequests": [{
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "预算未花完",
                "conditions": [{
                    "field": "daily_ratio_spent",
                    "operator": "LESS_THAN",
                    "value": 0.9,
                    "type": "FLOAT"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "YESTERDAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }, {
            "adAccountId": "",
            "emails": "",
            "status": 0,
            "strategyId": 0,
            "content": {
                "name": "效果好但预算未花完",
                "conditions": [{
                    "field": "cpa",
                    "operator": "LESS_THAN",
                    "value": 7,
                    "type": "INTEGER"
                }, {
                    "field": "offsite_conversion.fb_pixel_purchase",
                    "operator": "GREATER_THAN",
                    "value": "3",
                    "type": "INTEGER"
                }, {
                    "field": "schedule_start_minute",
                    "operator": "EQUAL",
                    "value": "120",
                    "type": "INTEGER"
                }, {
                    "field": "time_preset",
                    "operator": "EQUAL",
                    "value": "YESTERDAY",
                    "type": "ENUM_STRING"
                }, {
                    "field": "daily_ratio_spent",
                    "operator": "LESS_THAN",
                    "value": 0.9,
                    "type": "FLOAT"
                }, {
                    "field": "schedule_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "DAILY"
                }, {
                    "field": "entity_type",
                    "type": "STRING",
                    "operator": "EQUAL",
                    "value": "ADSET"
                }],
                "executions": [{
                    "field": "NOTIFICATION",
                    "struct": {}
                }]
            }
        }],
        "strategyRequest": {
            "accountNo": "",
            "campaigns": [],
            "name": "广告组每日预算未投完通知策略",
            "status": 0,
            "type": 2
        }
    }
]