<template>
  <div class="create-strategy-wrap">
    <div class="tpl-content bg-f mb-16">
      <div
        class="header py-16 px-24 dp-flex justify-btw"
        :class="{ 'none-back': $route.params.mark == 'nullNew' }"
      >
        <el-page-header
          @back="$router.push({ name: 'AutoRules' })"
          content="从模版创建策略"
        ></el-page-header>
        <el-popover popper-class="" placement="bottom-end" trigger="hover">
          <div v-html="whatAutoRule"></div>
          <span class="hover-theme-color cursor-pointer" slot="reference"
            >什么是自动规则？</span
          >
        </el-popover>
      </div>
      <el-form
        label-width="100px"
        size="small"
        :model="formModel"
        :rules="formRules"
      >
        <el-collapse class="p-24" accordion>
          <el-collapse-item v-for="(item, index) in strategyTpls" :key="index">
            <el-row slot="title" class="w-100 dp-flex align-item-c">
              <el-col :span="8" class="dp-flex align-item-c">
                <i class="el-icon-menu menu-i"></i>
                <div>
                  <p class="title-name">{{ item.name }}</p>
                  <p class="type f-14">{{ item.type }}</p>
                </div>
                <i class="el-icon-arrow-down ml-10 down-i dp-none"></i>
              </el-col>
              <el-col :span="16">
                <p>{{ item.msg }}</p>
              </el-col>
            </el-row>
            <div class="p-16">
              <el-form-item label="策略名称" prop="name" class="name-ipt">
                <el-input
                  clearable
                  :placeholder="item.name + '策略'"
                  v-model="item.nameModel"
                ></el-input>
              </el-form-item>
              <el-form-item v-if="index > 0">
                监控<el-button type="text" @click="openAdcampaigns(index)">{{
                  item.campaigns && item.campaigns.length
                    ? item.campaigns.length + "个广告系列"
                    : "所有广告系列"
                }}</el-button
                >的广告组
              </el-form-item>
              <!-- 条件 -->
              <el-form-item v-for="(c, ci) in item.conditions" :key="ci">
                <el-checkbox
                  v-model="c.check"
                  :disabled="c.disabled || !c.value"
                >
                  <span class="mr-5">{{ c.desc }}</span>
                  <el-input
                    type="number"
                    :placeholder="c.placeholder"
                    v-model.number="c.value"
                    style="width:200px;"
                    class="mr-5"
                    @change="
                      val =>
                        handleChangeConValue(val, { pindex: index, cindex: ci })
                    "
                  >
                    <template v-if="c.unit" slot="append">{{
                      c.unit
                    }}</template>
                  </el-input>
                  <!-- 且 -->
                  <template v-if="c.andDesc">
                    <span class="mr-5">{{ c.andDesc }}</span>
                    <el-input
                      type="number"
                      :placeholder="c.andPlaceholder"
                      v-model.number="c.andValue"
                      style="width:200px;"
                      class="mr-5"
                      @change="
                        val =>
                          handleChangeConValue(val, {
                            pindex: index,
                            cindex: ci
                          })
                      "
                    >
                      <template v-if="c.andUnit" slot="append">{{
                        c.andUnit
                      }}</template>
                    </el-input>
                  </template>
                  <span class="mr-5">{{ c.msg }}</span>
                  <el-input
                    clearable
                    size="mini"
                    style="width:150px;"
                    @keyup.enter="
                      val => {
                        item.email = val;
                      }
                    "
                    v-model="c.email"
                    placeholder="回车确认输入邮箱"
                  >
                    <i
                      @click="c.email = ''"
                      slot="suffix"
                      class="el-icon-close"
                    ></i>
                  </el-input>
                </el-checkbox>
              </el-form-item>
              <!-- 保存-取消 -->
              <el-form-item>
                <el-button
                  @click="handleSaveTpl(index)"
                  :disabled="!item.conditions.some(con => con.check)"
                  type="primary"
                  >保存
                </el-button>
                <el-button>取消 </el-button>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
    <div class="cus-content bg-f">
      <div class="header py-16 px-24">
        自定义创建策略
      </div>
      <el-collapse class="p-24" accordion>
        <el-collapse-item>
          <el-row slot="title" class="w-100 dp-flex align-item-c">
            <el-col :span="8" class="dp-flex align-item-c">
              <i class="el-icon-circle-plus-outline menu-i"></i>
              <div>
                <p class="title-name">创建自定义策略</p>
              </div>
              <i class="el-icon-arrow-down ml-10 down-i dp-none"></i>
            </el-col>
            <el-col :span="16">
              <p>有其他想法，尝试自己创建一个规则吧</p>
            </el-col>
          </el-row>
          <strategy-form
            @select-change="
              val => {
                obj.campaigns = [...val];
              }
            "
            :showSubmitBtn="true"
            class="p-16"
            :data="obj"
            @confirm-cus-strategy="handleConfirmCusStrategy"
          ></strategy-form>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 广告系列弹窗 -->
    <ad-campaigns-dialog
      :adcampaignsVisible="adcampaignsVisible"
      @close-dialog="adcampaignsVisible = false"
      :selectedCampaigns="selectedCampaigns"
      @select-change="handleConfirmCampaign"
    ></ad-campaigns-dialog>
  </div>
</template>

<script>
import adCampaignsDialog from "@/views/autoRules/components/adCampaignsDialog";
import strategyForm from "@/views/autoRules/components/strategyForm";
import {
  liststrategies,
  addStrategy,
  addtemplate,
  getUsers
} from "@/api/autoRules";
import { strategyTplData } from "./strategyTpl";
export default {
  components: { adCampaignsDialog, strategyForm },
  data() {
    return {
      whatAutoRule: ` <div  style="width: 300px; font-size: 12px; color: rgb(157, 160, 163); line-height: 22px;">
          <div style="text-indent: 2em;">
            设置了自动规则后，系统会定期检查你的广告系列，广告组和广告，当系统判断某个指标的变化符合设置的条件，就会立刻给你发一个通知或按照预先的设置做出调整动作。
          </div>
          <p>-你会每天定期检查投放预算有没有正常在花吗？</p>
          <p>-你会逐条检查并停掉那些点击率太低的广告吗？</p>
          <p>-你会开启多个广告系列来进行不同策略的测试，及时发现效果不够好的策略并关闭吗？</p><br/>
          <p>现在就开始设置你的自动规则吧！</p>
        </div>`,
      // 策略模板
      strategyTpls: [
        {
          name: "广告账户余额过少提醒",
          nameModel: "广告账户余额过少提醒",
          type: "广告系列策略",
          msg: "监控账号的余额，在低于预警线时向你发送通知，避免账户停投",
          conditions: [
            {
              check: false,
              desc: "当广告账户余额小于",
              value: 100,
              unit: "USD",
              msg: "时邮件通知我",
              email: "test.com",
              index: 0
            },
            {
              check: false,
              desc: "当广告账户余额小于",
              value: 3,
              unit: "",
              msg: "日花费(7日均值)时邮件通知我",
              email: "test.com",
              index: 1
            }
          ]
        },
        {
          name: "广告组投放不出通知",
          nameModel: "广告组投放不出通知",
          type: "广告系列策略",
          msg: "广告起量了吗？预算有没有正常在花？有异常时及时收到通知",
          conditions: [
            {
              check: false,
              desc: "当日预算花费小于",
              value: 1,
              unit: "USD",
              msg: "时邮件通知我",
              email: "test.com",
              index: 0
            },
            {
              check: false,
              desc: "当日展示数小于",
              value: 200,
              unit: "",
              msg: "时邮件通知我",
              email: "test.com",
              index: 1
            }
          ]
        },
        {
          name: "广告组CPA过高通知",
          nameModel: "广告组CPA过高通知",
          type: "广告系列策略",
          msg: "当投放的广告组CPA太高或没有转化时及时收到通知",
          conditions: [
            {
              check: false,
              desc: "当购买数（Web）大于3且CPA大于",
              value: "",
              placeholder: "输入不理想的CPA",
              unit: "USD",
              msg: "时邮件通知我",
              email: "test.com",
              index: 0
            },
            {
              check: false,
              desc: "当购买数（Web）大于3且花费大于",
              value: 30,
              unit: "USD",
              msg: "时邮件通知我",
              email: "test.com",
              index: 1
            }
          ]
        },
        {
          name: "广告组CPA简单启停通知",
          nameModel: "广告组CPA简单启停通知",
          type: "广告系列策略",
          msg: "当投放的广告组CPA太高或没有转化时，暂停广告组并收到通知",
          conditions: [
            {
              check: false,
              desc: "当广告组购买数（Web）大于3且CPA大于",
              value: "",
              placeholder: "输入不理想的CPA",
              unit: "USD",
              msg: "时暂停广告组，并邮件通知我",
              email: "test.com",
              index: 0
            },
            {
              check: false,
              desc: "当广告组购买数（Web）等于0且花费大于",
              value: 30,
              unit: "USD",
              msg: "时暂停广告组，并邮件通知我",
              email: "test.com",
              index: 1
            },
            {
              check: false,
              desc: "当广告组购买数（Web）大于3且CPA小于",
              value: 30,
              unit: "USD",
              msg: "时启动广告组，并邮件通知我",
              email: "test.com",
              index: 2
            }
          ]
        },
        {
          name: "广告组每日预算未投完通知",
          nameModel: "广告组每日预算未投完通知",
          type: "广告系列策略",
          msg: "设置的每日预算没花完，可能需要做些调整，邮件通知",
          conditions: [
            {
              check: false,
              desc: "当日预算花费小于",
              value: 90,
              unit: "%",
              msg: "时邮件通知我",
              email: "test.com",
              index: 0
            },
            {
              check: false,
              desc: "当购买数（Web）大于3且CPA小于",
              value: "",
              placeholder: "输入理想的CPA",
              unit: "USD",
              andDesc: "且预算花费小于",
              andValue: "90",
              andPlaceholder: "输入理想的CPA",
              andUnit: "%",
              msg: "邮件通知我",
              email: "test.com",
              index: 1
            }
          ]
        }
      ],
      selTplIndex: "",
      formModel: {},
      formRules: {},
      // 广告系列
      adcampaignsVisible: false,
      selectedCampaigns: [],
      obj: { name: "", type: 2, campaigns: [] },
      userInfo: {}
    };
  },
  methods: {
    getUsers() {
      this.$showLoading();
      getUsers().then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.userInfo = res.data;
          this.strategyTpls.forEach(item => {
            item.conditions.forEach(c => {
              c.email = res.data.email ? res.data.email : "";
            });
          });
        }
      });
    },
    addTplParamsFn(index) {
      let initTplItem = strategyTplData[index],
        tpl = this.strategyTpls[index],
        selCon = tpl.conditions.filter(item => item.check),
        addAdRuleRequests = initTplItem.addAdRuleRequests.filter((ar, ari) =>
          selCon.some(c => c.index == ari)
        ),
        params = {
          addAdRuleRequests,
          strategyRequest: initTplItem.strategyRequest
        },
        fbAccountId = this.$store.getters.fbAccountId;
      // addAdRuleRequests
      params.addAdRuleRequests.forEach((item, i) => {
        item.adAccountId = fbAccountId;
        item.emails = selCon[i].email;
        item.content.conditions[0].value = selCon[i].value;
        // 最后一个模板策略
        if (index == 4) {
          if (i == 1) {
            // daily_ratio_spent
            let sitem = item.content.conditions.find(
              c => c.field == "daily_ratio_spent"
            );
            sitem.value = selCon[i].andValue / 100;
            item.content.conditions[0].value = selCon[i].value;
          } else {
            item.content.conditions[0].value = selCon[i].value / 100;
          }
        }
      });
      // strategyRequest
      params.strategyRequest.accountNo = fbAccountId;
      params.strategyRequest.campaigns = tpl.campaigns ? tpl.campaigns : [];
      params.strategyRequest.name = tpl.nameModel;
      // console.log("params", params);
      return params;
    },
    addtemplate(index, name) {
      this.$showLoading();
      this.addTplParamsFn(index);
      addtemplate(this.addTplParamsFn(index)).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.$message.success(`"${name}"创建成功！`);
          this.$router.push({ name: "AutoRules" });
        }
      });
    },
    handleConfirmCampaign(mulCampaigns) {
      // console.log("mulCampaigns", mulCampaigns);
      let arr = [];
      mulCampaigns.forEach(item => {
        let obj = {
          id: item.id,
          name: item.name
        };
        arr.push(obj);
      });
      // console.log("mulCampaigns-arr", arr);
      this.$set(this.strategyTpls[this.selTplIndex], "campaigns", arr);
    },
    handleSaveTpl(index) {
      let name = this.strategyTpls[index].nameModel;
      this.addtemplate(index, name);
    },
    // 值改变控制当前是否可以选中（值为空时，不可选）
    handleChangeConValue(val, { pindex, cindex }) {
      let disabled = false;
      if (!val) {
        disabled = true;
      }
      this.$set(
        this.strategyTpls[pindex].conditions[cindex],
        "disabled",
        disabled
      );
    },
    addStrategy(obj) {
      this.$showLoading();
      let arr = [];
      this.obj.campaigns.forEach(item => {
        let obj = { id: item.id, name: item.name };
        arr.push(obj);
      });
      let params = {
        accountNo: this.$store.getters.fbAccountId,
        campaigns: arr,
        name: obj.name,
        type: obj.type
      };
      addStrategy(params).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.$message.success(`已成功添加策略“${obj.name}”！`);
          this.$router.push({ name: "AutoRules" });
        }
      });
    },
    handleConfirmCusStrategy(obj) {
      // console.log('confirm-',obj)
      this.addStrategy(obj);
    },
    // 策略列表
    liststrategies() {
      this.$showLoading();
      let params = { pageNum: 1, pageSize: 10 };
      // console.log(
      //   "this.$store.getters.fbAccountId, params",
      //   this.$store.getters.fbAccountId,
      //   params
      // );
      liststrategies(this.$store.getters.fbAccountId, params).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          if (res.data.result) {
            if (res.data.total) {
              this.$router.push({ name: "AutoRules" });
            }
          }
        }
      });
    },
    // 广告系列弹窗
    openAdcampaigns(index) {
      this.selTplIndex = index;
      this.selectedCampaigns = this.strategyTpls[index].campaigns
        ? this.strategyTpls[index].campaigns
        : [];
      this.adcampaignsVisible = true;
    }
  },
  mounted() {
    this.getUsers();
    // console.log("this.$route.params.mark", this.$route.params.mark);
    if (this.$route.params.mark != "new") {
      this.liststrategies();
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.create-strategy-wrap {
  .px-24 {
    padding: {
      left: 24px;
      right: 24px;
    }
  }
  .p-24 {
    padding: 24px;
  }
  .header {
    color: $theme-color;
    border-bottom: 1px solid #f0f0f0;
    @extend .f-16;
    .el-page-header__content {
      color: $theme-color;
    }
    & > span {
      color: rgb(112, 112, 112);
    }
    &.none-back {
      .el-page-header__left {
        @extend .dp-none;
      }
    }
  }
  .el-collapse-item__header {
    height: 64px;
    line-height: initial;
    // background: linear-gradient(
    //   360deg,
    //   rgba(229, 230, 233, 0.61) 0%,
    //   rgba(229, 230, 233, 0.41) 100%
    // );
    i {
      @extend .f-16;
    }
    .el-collapse-item__arrow.el-icon-arrow-right {
      @extend .dp-none;
    }
    &:hover,
    &.is-active {
      color: rgb(36, 122, 230);
      .title-name,
      .type {
        color: rgb(36, 122, 230);
      }
      .down-i {
        @extend .dp-block;
      }
      background: rgb(238, 244, 255) !important;
    }
  }
  .el-collapse-item:nth-child(2n-1) {
    .el-collapse-item__header {
      background: linear-gradient(
        360deg,
        rgba(229, 230, 233, 0.61) 0%,
        rgba(229, 230, 233, 0.41) 100%
      );
    }
  }
  .title-name {
    font-size: 17px;
    color: rgb(38, 38, 38);
    font-weight: bolder;
  }
  .type {
    color: rgb(185, 187, 190);
  }
  .menu-i {
    padding: 0 18px;
  }
  .name-ipt {
    .el-input {
      width: 500px;
    }
  }
  .el-input__suffix {
    @extend .dp-flex;
    @extend .align-item-c;
  }
  .mr-5 {
    margin-right: 5px;
  }
}
</style>